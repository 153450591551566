<template>
  <!-- 常驻业务线配置 -->
  <div class="container">
    <a-page-header title="常驻业务线配置" sub-title="SKU家庭单品" />

    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <div class="title">
            <span class="title-1">业务主线</span>
          </div>
        </div>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="dataSource"
          :pagination="false"
          :row-key="(record) => record.bcid"
          :loading="loading"
          :scroll="{ x: 660 }"
        >
          <template slot="type">
            <span>业务主线</span>
          </template>
          <template slot="action" slot-scope="record">
            <a-button
              type="link"
              @click="
                $jump({
                  path: '/businessConfigInfo',
                  query: {
                    id: record.bcid,
                    name: record.name
                  }
                })
              "
            >
              更多详情
            </a-button>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
export default {
  data() {
    return {
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '业务线名称',
          dataIndex: 'name',
          width: 200
        },
        {
          title: '业务线类型',
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true
    }
  },
  
  created() {
    this.getBusinessConfig()
  },
  methods: {
    getBusinessConfig() {
      // 获取常驻业务线配置
      const data = {
        page: 1,
        limit: 10
      }
      this.$axios.getBusinessConfig(data).then((res) => {
        this.dataSource = res.data.data
        this.loading = false
      })
    }
  }
}
</script>

<style scoped></style>
